import React, { useEffect, useState, useContext } from "react";
import { FormattedMessage } from "react-intl";
import {
  Spinner,
  Snackbar,
  SNACKBAR_TYPES,
  Icon,
  Button,
  Typography,
} from "@lysaab/ui-2";
import { getStatus, OnboardingStatus } from "../../data/signup";
import { LocalizationContext } from "../../state/LocalizationContext";
import "./Done.scss";
import { Plausible } from "../../utils/Plausible";
import { useSafeNavigation } from "../../hooks/useSafeNavigation";
import { preventUnload } from "../../components/intro/IntroEmailSection";
import { SignupId } from "@lysaab/lysa-onfido/data/signup";
import {
  EventTracker,
  TrackerEvent,
} from "../../utils/eventTracker/EventTracker";
import { useNavigateOut } from "../../hooks/useNavigateOut";

const POLL_TIMER = 3000;

interface Props {
  signupId: SignupId;
}

export function Done({ signupId }: Props) {
  const [status, setStatus] = useState<OnboardingStatus>();
  const localizationContext = useContext(LocalizationContext);
  const safeNavigation = useSafeNavigation();
  const [showManualReviewMsg, setShowManualReviewMsg] = useState(false);
  const navigateOut = useNavigateOut();

  useEffect(() => {
    const checkStatus = () => {
      if (!signupId) {
        setStatus(OnboardingStatus.NOT_FOUND);
        return;
      }

      getStatus(signupId)
        .then((response) => {
          setStatus(response.status);

          if (
            response.status === OnboardingStatus.PENDING_CREATION ||
            response.status === OnboardingStatus.ONFIDO_PENDING ||
            response.status === OnboardingStatus.ONFIDO_PENDING_MANUAL_REVIEW ||
            response.status === OnboardingStatus.PENDING_MANUAL_REVIEW
          ) {
            setTimeout(checkStatus, POLL_TIMER);
          } else {
            EventTracker.track({
              event: TrackerEvent.DONE,
              message: { status: response.status },
            });
            setShowManualReviewMsg(false);
          }

          if (
            response.status === OnboardingStatus.ONFIDO_PENDING_MANUAL_REVIEW ||
            response.status === OnboardingStatus.PENDING_MANUAL_REVIEW
          ) {
            setTimeout(() => setShowManualReviewMsg(true), 4000);
          }

          if (
            response.status === OnboardingStatus.ONFIDO_FAILED_DOCUMENT_TYPE ||
            response.status === OnboardingStatus.ONFIDO_FAILED_DOCUMENT ||
            response.status === OnboardingStatus.ONFIDO_FAILED_PHOTO
          ) {
            EventTracker.track({
              event: TrackerEvent.DONE,
              message: { status: response.status },
            });
            const country =
              localizationContext.state.country?.toLocaleLowerCase() || "";
            safeNavigation(`/${country}/onfido-reject/${signupId}`);
          }

          if (
            response.status === OnboardingStatus.ONFIDO_FAILED ||
            response.status === OnboardingStatus.NOT_FOUND
          ) {
            EventTracker.track({
              event: TrackerEvent.DONE,
              message: { status: response.status },
            });
          }
        })
        .catch(() => {
          setStatus(OnboardingStatus.NOT_FOUND);
          EventTracker.track({
            event: TrackerEvent.DONE,
            message: { status: OnboardingStatus.NOT_FOUND },
          });
        });
    };

    checkStatus();
  }, [signupId, safeNavigation, localizationContext.state.country]);

  useEffect(() => {
    window.removeEventListener("beforeunload", preventUnload);
  }, []);

  if (!status) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  // REJECTED_MINOR shouldn't happen, we're blocking minors earlier in the
  // signup process
  if (
    status === OnboardingStatus.NOT_FOUND ||
    status === OnboardingStatus.NOT_SIGNED ||
    status === OnboardingStatus.REJECTED_MINOR ||
    status === OnboardingStatus.LYSA_CANCELED
  ) {
    return (
      <div className="done-page">
        <h1>
          <FormattedMessage id="done.error.header" />
        </h1>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <FormattedMessage id="done.error.text" />
        </Snackbar>
        <Button
          block
          onClick={() => {
            Plausible.event({ name: Plausible.events.DONE_RETRY });
            const country =
              localizationContext.state.country?.toLocaleLowerCase() || "";
            safeNavigation(`/${country}`);
          }}
          label={<FormattedMessage id="done.error.button" />}
        />
      </div>
    );
  }

  if (
    status === OnboardingStatus.ONFIDO_PENDING ||
    status === OnboardingStatus.ONFIDO_PENDING_MANUAL_REVIEW
  ) {
    return (
      <div className="done-page">
        <h1>
          <FormattedMessage id="done.onfido_pending.header" />
        </h1>
        {showManualReviewMsg ? (
          <p>
            <FormattedMessage id="done.onfido_pending_manual.text" />
          </p>
        ) : (
          <p>
            <FormattedMessage id="done.onfido_pending.text" />
          </p>
        )}
        <Spinner />
      </div>
    );
  }

  // PEP or Onfido data mismatch
  if (status === OnboardingStatus.PENDING_MANUAL_REVIEW) {
    return (
      <div className="done-page">
        <h1>
          <FormattedMessage id="done.manual_review.header" />
        </h1>
        {showManualReviewMsg && (
          <p>
            <FormattedMessage id="done.manual_review.text" />
          </p>
        )}
        <Spinner />
      </div>
    );
  }

  if (status === OnboardingStatus.ONFIDO_FAILED) {
    return (
      <div className="done-page">
        <h1>
          <FormattedMessage id="done.onfido_error.header" />
        </h1>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <FormattedMessage id="done.onfido_error.text" />
        </Snackbar>
        <Button
          block
          onClick={() => {
            Plausible.event({
              name: Plausible.events.DONE_RETRY,
              props: { type: "onfido" },
            });
            const country =
              localizationContext.state.country?.toLocaleLowerCase() || "";
            safeNavigation(`/${country}/onfido-status/${signupId}`);
          }}
          label={<FormattedMessage id="done.onfido_error.button" />}
        />
      </div>
    );
  }

  if (status === OnboardingStatus.PENDING_CREATION) {
    return (
      <div className="done-page">
        <h1>
          <FormattedMessage id="done.pending.header" />
        </h1>
        <Spinner />
      </div>
    );
  }

  if (status === OnboardingStatus.ALREADY_CUSTOMER) {
    return (
      <div className="done-page">
        <h1>
          <FormattedMessage id="done.already-customer.header" />
        </h1>
        <Snackbar type={SNACKBAR_TYPES.INFO} icon>
          <Typography>
            <FormattedMessage id="done.already-customer.text" />
          </Typography>
        </Snackbar>
        <Button
          block
          onClick={() => {
            navigateOut.exitToLoggedIn("/login");
          }}
          label={<FormattedMessage id="done.already-customer.button" />}
        />
      </div>
    );
  }

  return (
    <div className="done-page">
      <h1 className="welcome-header">
        <FormattedMessage id="done.header" />
      </h1>
      <div className="logo-wrapper">
        <Icon.Logo size={40} />
      </div>
      <p>
        <FormattedMessage id="done.text" />
      </p>
      <Button
        block
        onClick={() => {
          Plausible.event({
            name: Plausible.events.DONE_DEPOSITS,
          });
          navigateOut.exitToLoggedIn("/deposits");
        }}
        label={<FormattedMessage id="done.button.deposit" />}
      />

      <Button
        variant="secondary"
        block
        onClick={() => {
          Plausible.event({
            name: Plausible.events.DONE_OVERVIEW,
          });
          navigateOut.exitToLoggedIn();
        }}
        label={<FormattedMessage id="done.button.login" />}
      />
    </div>
  );
}
