import { PepRequest } from "@lysaab/lysa-pep";
import { API, encode, WorldCountry } from "@lysaab/ui-2";
import { LysaCountry } from "@lysaab/shared";
import { Language } from "../state/LocalizationContext";
import { ReportingCountry } from "../state/SignupContext";
import { AccountQuestions } from "./types/AccountQuestions";
import {
  EligibilityFinancials,
  EligibilityRiskAnswers,
} from "./types/Eligibility";

export type SignupId = string & { readonly isSignupId: true };

export type AdviceRequest = {
  riskAnswers: EligibilityRiskAnswers;
  takenRisk: number;
  financial: EligibilityFinancials;
} & AccountQuestions;

export interface PersonRequest {
  email?: string;
  /** This can be any country, doesn't have to be a country Lysa exists in */
  citizenships: WorldCountry[];
  crs?: SaveSignupCrsRequest;
  sendInformationEmails?: boolean;
}

export interface PersonRequestSE {
  email?: string;
  /** This can be any country, doesn't have to be a country Lysa exists in */
  citizenships: WorldCountry[];
  crs?: SaveSignupCrsRequestSE;
}

export interface PersonNetsRequestDK extends Omit<PersonRequest, "crs"> {
  crs: SaveSignupCrsNetsRequestDK;
}

export interface PersonNetsRequestFI extends Omit<PersonRequest, "crs"> {
  crs: SaveSignupCrsNetsRequest;
}

interface KycAnswers {
  question: string;
  answers: string[];
}

interface SaveSignupLoginRequest {
  signedEmail: string;
  password: string;
  /**
   * Only for those who choose to enable totp
   */
  signedSecret?: string;
}

interface SaveSignupCrsRequest {
  /**
   * List of countries the customer needs to do tax reporting in.
   * For countries using Nets this should exclude the country the customer is
   * signing up in
   */
  reportingCountries: ReportingCountry[];
  residentCountry: string;
  /** Max length = 200 */
  firstName: string;
  /** Max length = 200 */
  lastName: string;
  /** Max length = 300 */
  street: string;
  postCode: string;
  /** Max length = 300 */
  city: string;
  /** Format yyyy-MM-dd */
  birthday: string;
  birthCountry?: WorldCountry;
  /** Max length = 300 */
  birthCity?: string;
}

interface SaveSignupCrsNetsRequest {
  /** This list must include the country the user is signing up in */
  reportingCountries: ReportingCountry[];
  residentCountry: string;
  /** Max length = 300 */
  street: string;
  postCode: string;
  /** Max length = 300 */
  city: string;
  birthCountry?: WorldCountry;
  /** Max length = 300 */
  birthCity?: string;
}

interface SaveSignupCrsNetsRequestDK extends SaveSignupCrsNetsRequest {
  /** Max length = 200 */
  firstName: string;
  /** Max length = 200 */
  lastName: string;
}

export interface SaveSignupCrsRequestSE {
  reportingCountries: ReportingCountry[];
  residentCountry?: string;
  /**
   * Max length = 300
   */
  street?: string;
  postCode?: string;
  /**
   * Max length = 300
   */
  city?: string;
}

export interface SaveSignupRequestBase {
  signupId?: SignupId;
  country: LysaCountry;
  language: Language;
  inviteId?: string;
  accountName: string;
  advice: AdviceRequest;
  pep: PepRequest;
  kyc: {
    customerKyc: {
      version: string;
      questionAnswers: KycAnswers[];
    };
    accountKyc: {
      version: string;
      questionAnswers: KycAnswers[];
    };
  };
}

// TODO: Delete
// export interface SaveSignupRequest extends SaveSignupRequestBase {
//   person: PersonRequest;
// }

export interface SaveSignupOnfidoRequest extends SaveSignupRequestBase {
  person: PersonRequest;
  login: SaveSignupLoginRequest;
}

export interface SaveSignupRequestSE extends SaveSignupRequestBase {
  person: PersonRequestSE;
  signedEmail: string;
}

export interface SaveSignupNetsRequestDK extends SaveSignupRequestBase {
  signedEmail: string;
  person: PersonNetsRequestDK;
}

export interface SaveSignupNetsRequestFI extends SaveSignupRequestBase {
  signedEmail: string;
  person: PersonNetsRequestFI;
}

export enum OnboardingStatus {
  NOT_FOUND = "NOT_FOUND",
  NOT_SIGNED = "NOT_SIGNED",

  /** Waiting for Onfido to validate the documents submitted by the customer */
  ONFIDO_PENDING = "ONFIDO_PENDING",
  /** Onfido's automatic validation failed. Manual review. Up to 24h */
  ONFIDO_PENDING_MANUAL_REVIEW = "ONFIDO_PENDING_MANUAL_REVIEW",

  /** User did not complete all onfido steps yet */
  ONFIDO_PENDING_USER = "ONFIDO_PENDING_USER",

  /** Unknown Onfido failure, might be a user error */
  ONFIDO_FAILED = "ONFIDO_FAILED",

  /** User is multiple customer or multiple passport */
  LYSA_CANCELED = "LYSA_CANCELED",

  /** Onfido failures that the user can fix by retrying */
  ONFIDO_FAILED_DOCUMENT_TYPE = "ONFIDO_FAILED_DOCUMENT_TYPE",
  ONFIDO_FAILED_DOCUMENT = "ONFIDO_FAILED_DOCUMENT",
  ONFIDO_FAILED_PHOTO = "ONFIDO_FAILED_PHOTO",

  PENDING_MANUAL_REVIEW = "PENDING_MANUAL_REVIEW",
  PENDING_CREATION = "PENDING_CREATION",
  COMPLETE = "COMPLETE",

  /** Too young */
  REJECTED_MINOR = "REJECTED_MINOR",
  /**
   * Signer is already a customer.
   */
  ALREADY_CUSTOMER = "ALREADY_CUSTOMER",
}

export interface InitiateResponse {
  signupId: SignupId;
}

export interface OnboardingStatusResponse {
  trackId: string;
  status: OnboardingStatus;
}

export interface SaveSignupResponse {
  signupId: SignupId;
}

export interface TotpSetupInitResponse {
  secret: string;
  signature: string;
}

export interface TotpSetupRequest {
  code: string;
  secret: string;
  signature: string;
}

export interface TotpSetupResponse {
  signedSecret: string;
}

export interface VerifyEmail {
  email: string;
  language: Language;
  country: LysaCountry;
}

export interface VerifyEmailResponse {
  confirmationId: string; // "4464562e-3e50-4424-b19e-5f26475c366c"
  expire: string; // "2022-03-28T12:59:51.883853353Z"
  statusIdResponse: string; // "EM9kTQQ8T-p4CvyZ2OADfW0="
}

export interface VerifyEmailCode {
  confirmationId: string;
  code: string;
}

export enum EmailVerificationCodeResult {
  OK = "OK",
  EXPIRED = "EXPIRED",
  NOT_VALID = "NOT_VALID",
}

export interface VerifyEmailCodeResponse {
  result: EmailVerificationCodeResult;
  signedEmail: string;
}

export interface ReminderEmail {
  email: string;
  language: Language;
  country: LysaCountry;
}

export function initiate(email: string, countryCode: LysaCountry) {
  return API.post<InitiateResponse>(`/signup/person/initiate`, {
    email,
    countryCode,
  });
}

export function saveSignup(data: SaveSignupOnfidoRequest) {
  return API.post<SaveSignupResponse>("/signup/person", data);
}

export function saveSignupSE(data: SaveSignupRequestSE) {
  return API.post<SaveSignupResponse>("/signup/person", data);
}

export function saveSignupNetsDK(data: SaveSignupNetsRequestDK) {
  return API.post<SaveSignupResponse>("/signup/person", data);
}

export function saveSignupNetsFI(data: SaveSignupNetsRequestFI) {
  return API.post<SaveSignupResponse>("/signup/person", data);
}

export function getStatus(signupId: SignupId) {
  return API.get<OnboardingStatusResponse>(
    encode`/signup/person/status?signupId=${signupId}`,
    true
  );
}

export function saveInterested(
  email: string,
  language: Language,
  country: LysaCountry
) {
  return API.post("/signup/person/interested", { email, language, country });
}

export function removeInterested(email: string, country: LysaCountry) {
  return API.post("/signup/person/uninterested", { email, country });
}

export function totpSetupInit() {
  return API.get<TotpSetupInitResponse>("/signup/person/totp");
}

export function totpSetup(data: TotpSetupRequest) {
  return API.post<TotpSetupResponse>("/signup/person/totp", data);
}

export function verifyEmail(data: VerifyEmail) {
  return API.post<VerifyEmailResponse>("/signup/person/email", data);
}

export function verifyEmailCode(data: VerifyEmailCode) {
  return API.post<VerifyEmailCodeResponse>(
    "/signup/person/email/confirm",
    data
  );
}

export function enqueueReminderEmail(data: ReminderEmail) {
  return API.post("/signup/person/reminder", data);
}
