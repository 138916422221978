import { useMemo } from "react";
import { LysaCountry } from "@lysaab/shared";
import { useLocalizationContext } from "../state/LocalizationContext";

const exitPaths: Record<LysaCountry, string> = {
  [LysaCountry.SWEDEN]: "https://lysa.se",
  [LysaCountry.FINLAND]: "https://lysa.fi",
  [LysaCountry.DENMARK]: "https://lysa.dk",
  [LysaCountry.GERMANY]: "https://lysa.de",
  [LysaCountry.SPAIN]: "https://www.es.lysa.com",
};

type ValidPathsType = "/monthly" | "/isk-transfer" | "/deposits" | "/login";

export function useNavigateOut() {
  const localizationContext = useLocalizationContext();

  return useMemo(() => {
    return {
      exitToPublicSite: () => {
        window.location.href =
          exitPaths[localizationContext.state.country ?? LysaCountry.SWEDEN];
      },
      exitToLoggedIn: (path?: ValidPathsType) => {
        window.location.href =
          process.env.REACT_APP_APP?.replace(
            "<country>",
            localizationContext.state.country?.toLocaleLowerCase() || ""
          ) + (path ?? "");
      },
    };
  }, [localizationContext]);
}
