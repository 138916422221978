import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { postFunnelPageview } from "../data/cts";
import { LocalizationContext } from "../state/LocalizationContext";
import { useQuery } from "./useQuery";
import {
  Plausible,
  insertPlausibleScript,
  savingsAccountUuidRegExp,
  uuidRegExp,
} from "../utils/Plausible";

interface Query {
  from?: string;
}

export function usePageTracking() {
  const country = useContext(LocalizationContext).state.country;
  const location = useLocation();
  const prevPathname = useRef<string>();
  const query = useQuery<Query>();
  const [isFromApp] = useState(query.from === "lysa-app");

  useEffect(() => {
    insertPlausibleScript();
  }, []);

  useEffect(() => {
    if (!country) {
      return;
    }

    Plausible.setProp("country", country);
    Plausible.setProp("isFromApp", isFromApp);

    const sanitizedUrl = location.pathname
      .replace(savingsAccountUuidRegExp, "")
      .replace(uuidRegExp, "");
    const searchParams = new URLSearchParams(location.search);
    const utmParams = Array.from(searchParams.entries()).filter(([key]) =>
      key.startsWith("utm_")
    );
    const sanitizedParams = new URLSearchParams(utmParams).toString();

    const sanitizedUrlWithParams =
      sanitizedUrl + (sanitizedParams ? "?" + sanitizedParams : "");

    if (prevPathname.current !== sanitizedUrl) {
      postFunnelPageview(sanitizedUrl, country, isFromApp).catch((error) => {
        // Don't throw an error if this isn't working
        console.error(error);
      });
      Plausible.trackPageView(sanitizedUrlWithParams);
      prevPathname.current = sanitizedUrl;
    }
  }, [country, isFromApp, location]);
}
