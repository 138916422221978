import { LysaCountry } from "@lysaab/shared";

type ValueOf<T> = T[keyof T];

type PlausibleCustomEvents =
  typeof Plausible.events[keyof typeof Plausible.events];

type PlausibleFunction = {
  (event: "pageview", config: { u: string }): void;
  (
    event: PlausibleCustomEvents,
    // u here is not technically required by Plausible for events, but
    // we make it required because we want to make sure we sanitize it.
    // Typing it so we have to pass it in explicitly helps us remember.
    // (Should only be done via the Plausible.event anyway)
    config: { u: string; props: {}; callback: () => void }
  ): void;
};

declare global {
  interface Window {
    plausible: PlausibleFunction & { q: unknown[] };
  }
}

window.plausible =
  window.plausible ||
  function () {
    (window.plausible.q = window.plausible.q || []).push(arguments);
  };

/* This is a uuid-like matcher. Different uuid-variants specifies certain numbers/letters which parts of the id should begin with. This matches more generally */
export const uuidRegExp =
  /\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;
export const savingsAccountUuidRegExp =
  /\/s?[0-9a-f]{7}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;

type PersistentPropsType = {
  // utm_campaign is automatically tracked by Plausible, this
  // prop is used to track our own campaign concept which
  // can be different
  campaign?: string;
  country?: LysaCountry;
  isFromApp?: boolean;
};

export class Plausible {
  static events = {
    DONE_DEPOSITS: "done_deposits" as const,
    DONE_MONTHLY: "done_monthly" as const,
    DONE_ISK_TRANSFER: "done_isk_transfer" as const,
    DONE_OVERVIEW: "done_overview" as const,
    DONE_RETRY: "done_retry" as const,
    ABOUT_YOU_SPAM_YES: "about_you_spam_yes" as const,
    ABOUT_YOU_SPAM_NO: "about_you_spam_no" as const,
    EXIT_NO_REASON: "exit_no_reason" as const,
    EXIT_LATER: "exit_later" as const,
    EXIT_COMPLICATED: "exit_complicated" as const,
    EXIT_LONG: "exit_long" as const,
    EXIT_PERSONAL: "exit_personal" as const,
    EXIT_OTHER: "exit_other" as const,
    EXIT_NO_ANSWER: "exit_no_answer" as const,
    EXIT_CLOSE: "exit_close" as const,
  };

  static trackPageView(url: string) {
    if (typeof url !== "string") {
      return;
    }

    // This is to sanitise tracked urls since they can contain account and customer ids
    const sanitizedUrl = url.replace(uuidRegExp, "");

    // Note that this does not track props currently, including the persistent props.
    // Plausible has support for this, but it does currently not show up for pageviews
    // in our dashboard for unknown reason.
    window.plausible("pageview", {
      u: window.location.origin + sanitizedUrl,
    });
  }

  static persistentProps: PersistentPropsType = {};
  static event({
    name,
    props,
  }: {
    name: ValueOf<typeof Plausible.events>;
    props?: Record<string, string | number | boolean>;
  }) {
    return new Promise<void>((resolve) => {
      const eventProps = {
        ...Plausible.persistentProps,
        ...props,
      };

      // In a perfect world, we would use the url from the router here,
      // but that would mean this needs to be a hook or we need to pass
      // in location to this function. `window.location.href` is what
      // Plausible uses if you don't pass in a u-option, and the important
      // thing here is to remove any uuids from the url, so this is good enough.
      const sanitizedUrl = window.location.href.replace(uuidRegExp, "");

      window.plausible(name, {
        u: sanitizedUrl,
        props: eventProps,
        // This callback is always called, even if the request fails
        callback: () => {
          resolve();
        },
      });
    });
  }

  // This is used to set props that should be included in all future events
  static setProp<T extends keyof PersistentPropsType>(
    name: T,
    value: PersistentPropsType[T]
  ) {
    Plausible.persistentProps[name] = value;
  }
}

let hasInjected = false;
export function insertPlausibleScript() {
  if (hasInjected) {
    return;
  }
  const newScript = document.createElement("script");
  const existingScriptTag = document.getElementsByTagName("script")[0];
  newScript.defer = true;
  newScript.src = "https://analytics.lysa.se/js/script.local.manual.js";
  newScript.dataset.domain =
    process.env.NODE_ENV === "production"
      ? window.location.hostname
      : "signup.lysa-test.se";
  existingScriptTag.parentNode?.insertBefore(newScript, existingScriptTag);
  hasInjected = true;
}
