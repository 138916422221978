import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from "react";
import { FormattedMessage } from "react-intl";
import {
  Spinner,
  Snackbar,
  SNACKBAR_TYPES,
  Icon,
  Button,
  Typography,
} from "@lysaab/ui-2";
import { getStatus, OnboardingStatus, SignupId } from "../../../data/signup";
import { useParams } from "react-router-dom";
import { LocalizationContext } from "../../../state/LocalizationContext";
import "./Done.scss";
import { Plausible } from "../../../utils/Plausible";
import { useSafeNavigation } from "../../../hooks/useSafeNavigation";
import { preventUnload } from "../../../components/intro/IntroEmailSection";
import {
  EventTracker,
  TrackerEvent,
} from "../../../utils/eventTracker/EventTracker";
import { useNavigateOut } from "../../../hooks/useNavigateOut";

interface Match {
  signupId: string;
}

const POLL_TIMER = 3000;

export function Done() {
  const match = useParams<Match>();
  const [status, setStatus] = useState<OnboardingStatus>();
  const signupId = useRef<SignupId>();
  const localizationContext = useContext(LocalizationContext);
  const safeNavigation = useSafeNavigation();
  const navigateOut = useNavigateOut();

  const checkStatus = useCallback(() => {
    getStatus(match.signupId as SignupId)
      .then((response) => {
        setStatus(response.status);
        if (response.status === OnboardingStatus.PENDING_CREATION) {
          setTimeout(checkStatus, POLL_TIMER);
        } else {
          EventTracker.track({
            event: TrackerEvent.DONE,
            message: { status: response.status },
          });
        }
      })
      .catch(() => {
        setStatus(OnboardingStatus.NOT_FOUND);
        EventTracker.track({
          event: TrackerEvent.DONE,
          message: { status: OnboardingStatus.NOT_FOUND },
        });
      });
  }, [match.signupId]);

  useEffect(() => {
    if (match.signupId !== signupId.current) {
      signupId.current = match.signupId as SignupId;
      checkStatus();
    }
  }, [checkStatus, match.signupId]);

  useEffect(() => {
    window.removeEventListener("beforeunload", preventUnload);
  }, []);

  if (!status) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  if (status === OnboardingStatus.REJECTED_MINOR) {
    return (
      <div className="done-page-sweden">
        <h1>
          <FormattedMessage id="sweden.done.error.minor.header" />
        </h1>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <FormattedMessage id="sweden.done.error.minor.text" />
        </Snackbar>
      </div>
    );
  }

  if (
    status === OnboardingStatus.NOT_FOUND ||
    status === OnboardingStatus.NOT_SIGNED
  ) {
    return (
      <div className="done-page-sweden">
        <h1>
          <FormattedMessage id="sweden.done.error.header" />
        </h1>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <FormattedMessage id="sweden.done.error.text" />
        </Snackbar>
        <Button
          block
          onClick={() => {
            Plausible.event({ name: Plausible.events.DONE_RETRY });
            const country =
              localizationContext.state.country?.toLocaleLowerCase() || "";
            safeNavigation(`/${country}`);
          }}
          label={<FormattedMessage id="sweden.done.error.button" />}
        />
      </div>
    );
  }

  if (status === OnboardingStatus.PENDING_CREATION) {
    return (
      <div className="done-page-sweden">
        <h1>
          <FormattedMessage id="sweden.done.pending.header" />
        </h1>
        <Spinner />
      </div>
    );
  }

  if (status === OnboardingStatus.PENDING_MANUAL_REVIEW) {
    return (
      <div className="done-page-sweden">
        <h1>
          <FormattedMessage id="sweden.done.manual.header" />
        </h1>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <FormattedMessage
            id="sweden.done.manual.text"
            values={{
              bold: (str) => <b>{str}</b>,
              break: (parts: (string | React.ReactNode)[]): React.ReactNode => (
                <>
                  {parts}
                  <br />
                  <br />
                </>
              ),
            }}
          />
        </Snackbar>
      </div>
    );
  }

  if (status === OnboardingStatus.ALREADY_CUSTOMER) {
    return (
      <div className="done-page-sweden">
        <h1>
          <FormattedMessage id="sweden.done.already-customer.header" />
        </h1>
        <Snackbar type={SNACKBAR_TYPES.INFO} icon>
          <Typography>
            <FormattedMessage id="sweden.done.already-customer.text" />
          </Typography>
        </Snackbar>
        <Button
          block
          onClick={() => {
            navigateOut.exitToLoggedIn("/login");
          }}
          label={<FormattedMessage id="sweden.done.already-customer.button" />}
        />
      </div>
    );
  }

  return (
    <div className="done-page-sweden">
      <h1 className="welcome-header">
        <FormattedMessage id="sweden.done.header" />
      </h1>
      <div className="logo-wrapper">
        <Icon.Logo size={40} />
      </div>
      <p>
        <FormattedMessage id="sweden.done.text" />
      </p>
      <Button
        block
        onClick={() => {
          Plausible.event({
            name: Plausible.events.DONE_DEPOSITS,
          });
          navigateOut.exitToLoggedIn("/deposits");
        }}
        label={<FormattedMessage id="sweden.done.button.deposit" />}
      />
      <Button
        variant="secondary"
        block
        onClick={() => {
          Plausible.event({
            name: Plausible.events.DONE_MONTHLY,
          });
          navigateOut.exitToLoggedIn("/monthly");
        }}
        label={<FormattedMessage id="sweden.done.button.monthly" />}
      />
      <Button
        variant="secondary"
        block
        onClick={() => {
          Plausible.event({
            name: Plausible.events.DONE_ISK_TRANSFER,
          });
          navigateOut.exitToLoggedIn("/isk-transfer");
        }}
        label={<FormattedMessage id="sweden.done.button.isk" />}
      />
      <Button
        variant="secondary"
        block
        onClick={() => {
          Plausible.event({
            name: Plausible.events.DONE_OVERVIEW,
          });
          navigateOut.exitToLoggedIn();
        }}
        data-test-id="done-overview-button"
        label={<FormattedMessage id="sweden.done.button.login" />}
      />
    </div>
  );
}
